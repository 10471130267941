import React from "react";
import Main from "../page/Main";
import Layout from "../other/layout";
import SEO from "../other/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Main />
  </Layout>
);

export default IndexPage;
