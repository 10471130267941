import React from "react";

import { useIntl, Link, navigate } from "gatsby-plugin-react-intl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import FadeIn from 'react-fade-in';

var activeCarousel = 1;

const responsiveplus = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1600 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1360 },
    items: 1,
  },
  mdDesktop: {
    breakpoint: { max: 1360, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 520 },
    items: 1,
  },
  xsMobile: {
    breakpoint: { max: 520, min: 0 },
    items: 1,
  },
};
const responsiveowl = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1600 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1360 },
    items: 1,
  },
  mdDesktop: {
    breakpoint: { max: 1360, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 520 },
    items: 1,
  },
  xsMobile: {
    breakpoint: { max: 520, min: 0 },
    items: 1,
  },
};
function Main(props) {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query {
      grow: file(relativePath: { eq: "desktopBanner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      growt: file(relativePath: { eq: "officeinfo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      c1: file(relativePath: { eq: "Logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const handleQuoteSlider = (direction, max) => {
    var sliderCur = document.getElementById("quote" + activeCarousel);
    sliderCur.classList.add("hidden");

    if (direction == "next" && activeCarousel < max) {
      activeCarousel = activeCarousel + 1;
    } else if (direction == "next" && activeCarousel == max) {
      activeCarousel = 1;
    } else if (direction == "prev" && activeCarousel > 1) {
      activeCarousel = activeCarousel - 1;
    } else if (direction == "prev" && activeCarousel == 1) {
      activeCarousel = max;
    }

    var sliderNew = document.getElementById("quote" + activeCarousel);
    sliderNew.classList.remove("hidden");
  };

  return (
    <div id="bd">
      <div id="homeBlock1" className="homeBlock">
        <FadeIn delay={300} transitionDuration={700}>
          <div className="playdiv">
          <Carousel
                      swipeable={false}
                      draggable={false}
                      showDots={true}
                      responsive={responsiveowl}
                      ssr={false} // means to render carousel on server-side.
                      infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={5000}
                      keyBoardControl={true}
                      transitionDuration={500}
                      removeArrowOnDeviceType={["desktop", "tablet", "mobile", "xsMobile"]}
                      // deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                    >
                      <div className="owl-item">
                        <Img
                            fluid={data.grow.childImageSharp.fluid}
                            className="img-responsive"
                          />
                      </div>
                      <div className="owl-item">
                        <Img
                            fluid={data.grow.childImageSharp.fluid}
                            className="img-responsive"
                          />
                      </div>
                      <div className="owl-item">
                        <Img
                            fluid={data.grow.childImageSharp.fluid}
                            className="img-responsive"
                          />
                      </div>
                    </Carousel>
            
          </div>

          <div className="container MarginTopthree MarginBottomthree mobileColumnReverse">
            <div className="blogListing col-md-6 col-sm-12">
              <div className="scaleYourBusiness">
                <h4>{intl.formatMessage({ id: "static-s1" })}</h4>
                <span className="fadeInUp anime-delay">
                  {intl.formatMessage({ id: "static-s2" })} <br /><br />
                  {intl.formatMessage({ id: "static-s3" })} <br /><br />
                  {intl.formatMessage({ id: "static-s4" })}
                </span>
              </div>
            </div>
            <div
              className="blogListing col-md-6 col-sm-12 mobileImgereverse"
              style={{
                paddingTop: "5%",
              }}
            >
              <div className="playdiv">
                <Img
                  fluid={data.growt.childImageSharp.fluid}
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </FadeIn>

        <div
          className="row"
          style={{
            background: "#f1f1f1",
            width: "100%",
            paddingBottom: "5%",
          }}
        >
          <div className="container MarginTop marketplacePlus">
            <h1 className="fadeInUp anime-delay3">{intl.formatMessage({ id: "static-s5" })}</h1>
            <p className="fadeInUp anime-delay4">{intl.formatMessage({ id: "static-s6" })}</p>
            <div className="flex-container">
              <div className="flex-item-left">
                <div className="courses-container">
                  <div className="course">
                    <div className="course-preview" style={{
                      background: "#d4504e"
                    }}>
                      <h6>Our Services</h6>
                      <h5>{intl.formatMessage({ id: "static-s7" })}</h5>
                      <Link to={"/fair-and-event-services"}>{intl.formatMessage({ id: "static-s10" })}</Link>
                    </div>
                    <div className="course-info">
                      <h6>{intl.formatMessage({ id: "static-s8" })}</h6>
                      <h3>{intl.formatMessage({ id: "static-s9" })}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-item-right">
                <div className="courses-container">
                  <div className="course">
                    <div className="course-preview" style={{
                      background: "rgb(84 126 216)"
                    }}>
                      <h6>Our Services</h6>
                      <h5>{intl.formatMessage({ id: "static-s11" })}</h5>
                      <Link to={"/technology-services"}>{intl.formatMessage({ id: "static-s10" })}</Link>
                    </div>
                    <div className="course-info">
                      <h6>{intl.formatMessage({ id: "static-s12" })}</h6>
                      <h3>{intl.formatMessage({ id: "static-s13" })}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-item-left">
                <div className="courses-container">
                  <div className="course">
                    <div className="course-preview" style={{
                      background: "rgb(251 198 21)"
                    }}>
                      <h6>Our Services</h6>
                      <h5>{intl.formatMessage({ id: "static-s14" })}</h5>
                      <Link to={"/association-sanagement-services"}>{intl.formatMessage({ id: "static-s10" })}</Link>
                    </div>
                    <div className="course-info">
                      <h6>{intl.formatMessage({ id: "static-s15" })}</h6>
                      <h3>{intl.formatMessage({ id: "static-s16" })}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-container">
            <div className="flex-item-right">
                <div className="courses-container">
                  <div className="course">
                    <div className="course-preview">
                      <h6>Our Services</h6>
                      <h5>{intl.formatMessage({ id: "static-s17" })}</h5>
                      <Link to={"/leadership-academy"}>{intl.formatMessage({ id: "static-s22" })}</Link>
                    </div>
                    <div className="course-info">
                      <h6>{intl.formatMessage({ id: "static-s17" })}</h6>
                      <h3>{intl.formatMessage({ id: "static-s18" })}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-item-left">
                <div className="courses-container">
                  <div className="course">
                    <div className="course-preview">
                      <h6>Our Services</h6>
                      <h5>{intl.formatMessage({ id: "static-s19" })}</h5>
                      <Link to={"/frequently-asked-questions"}>{intl.formatMessage({ id: "static-s22" })}</Link>
                    </div>
                    <div className="course-info">
                      <h6>{intl.formatMessage({ id: "static-s19" })}</h6>
                      <h3>{intl.formatMessage({ id: "static-s20" })}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-item-right">
                <div className="courses-container">
                  <div className="course">
                    <div className="course-preview">
                      <h6>Our Services</h6>
                      <h5>{intl.formatMessage({ id: "static-s31" })}</h5>
                      <Link to={"/message-from-ceo"}>{intl.formatMessage({ id: "static-s10" })}</Link>
                    </div>
                    <div className="course-info">
                      <h6>{intl.formatMessage({ id: "static-s31" })}</h6>
                      <h3>{intl.formatMessage({ id: "message-ceo" })}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-indent default">
          <div className="bgim hero falling-snow style2 hero-e8e82ea94857 hero__overlay wow fadeIn lazyloaded">
            <div className="hero__inner">
              <div className="page-width">
                <div className="wrap-text center">
                  <div className="rte-setting mega-subtitle heroTxt">
                    <Carousel
                      swipeable={false}
                      draggable={false}
                      showDots={true}
                      responsive={responsiveplus}
                      ssr={false} // means to render carousel on server-side.
                      infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={5000}
                      keyBoardControl={true}
                      transitionDuration={500}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["desktop", "tablet", "mobile", "xsMobile"]}
                      // deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                    >
                      <div className="owl-item mlr">
                        <div className="custsays">

                          <div>
                            <Img
                              fluid={data.c1.childImageSharp.fluid}
                              className="img-responsive"
                            />
                          </div>
                          <div className="custcontent">What will working with Marathon bring you?</div>
                          <div className="custowner">Income generating activities and total revenues of your institution will increase</div>

                        </div>
                      </div>
                      <div className="owl-item mlr">
                        <div className="custsays">
                          <div>
                            <Img
                              fluid={data.c1.childImageSharp.fluid}
                              className="img-responsive"
                            />
                          </div>
                          <div className="custcontent">What will working with Marathon bring you?</div>
                          <div className="custowner">Income generating activities and total revenues of your institution will increase</div>
                        </div>
                      </div>
                      <div className="owl-item mlr">
                        <div className="custsays">
                          <div>
                            <Img
                              fluid={data.c1.childImageSharp.fluid}
                              className="img-responsive"
                            />
                          </div>
                          <div className="custcontent">What will working with Marathon bring you?</div>
                          <div className="custowner">Income generating activities and total revenues of your institution will increase</div>
                        </div>
                      </div>
                      <div className="owl-item mlr">
                        <div className="custsays">
                          <div>
                            <Img
                              fluid={data.c1.childImageSharp.fluid}
                              className="img-responsive"
                            />
                          </div>
                          <div className="custcontent">What will working with Marathon bring you?</div>
                          <div className="custowner">Income generating activities and total revenues of your institution will increase</div>
                        </div>
                      </div>

                    </Carousel>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container MarginTopthree1 customersays">
          <h1>{intl.formatMessage({ id: "static-s23" })}</h1>

          <div className="blogListing col-md-12 col-sm-12 MarginToptwo assessment">

            <p>{intl.formatMessage({ id: "static-s24" })}</p>

            <div className="blazi" style={{
              textAlign: "center"
            }}><Link to={"/contact"} className="btn btn-default bookMeetingBtn">{intl.formatMessage({ id: "static-s25" })}</Link></div>

          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default Main;
